<template>
  <div class="text-center ImageGallery">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      class="imageGalleryDialog"
      :width="$vuetify.breakpoint.mobile ? '95%' : '80%'"
      content-class="animalGalleryDialogAct"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class=" ">
          Gallery
        </span>
      </template>

      <v-card class="">
        <v-toolbar dense dark :color="appSettings.mediaButtonColor">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Gallery</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="" ref="box" style="background: black;">
          <v-carousel
            class="fullHeightMax"
            v-if="imageFilesJoined && imageFilesJoined.length"
            hide-delimiters
            :show-arrows="imageFilesJoined.length > 1"
            height="auto"
            style=""
          >
            <v-carousel-item
              class="fullHeight"
              v-for="(item, i) in imageFilesJoined"
              :key="i"
            >
              <div class="fullHeight">
                <div class="fullHeight">
                  <center
                    class="ximageWrapperWrapper"
                    style="position: relative;top: 50%;transform: translateY(-50%);"
                  >
                    <div class="ximageWrapper">
                      <img
                        :src="item.src_gallery"
                        class="fullHeightMax"
                        style="
              
                  display: block;
                  max-width: 100%;
                  width: auto;
                  height: auto;"
                      />

                      <img
                        v-if="imageFilesJoined[i + 1]"
                        :src="imageFilesJoined[i + 1].src_gallery"
                        style="
                        opacity: 0.01;
                        display: block;
                        width: 1px;
                        height: 1px;"
                      />

                      <div class="imageCaption">
                        <font v-if="item.Point_Name"
                          >{{ item.Point_Name }} |
                        </font>

                        <a @click="loadMoreInfo(item.index)">
                          More Info
                        </a>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>

        <div
          v-for="(item, i) in media"
          :key="'x_' + i"
          style="height:0;width:0;overflow:hidden;"
        >
          <img
            :src="item.src_gallery"
            key=""
            class="fullHeightMax"
            style="
              
                  display: block;
                  
                  width: 1px;
                  height: 0;"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  props: {
    title: String,
    width: String,
    media: Array
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList
    }),
    imageFilesJoined() {
      let imageFilesJoined = [];

      let markers = this?.markerList?.data?.features;
      if (markers?.length) {
        markers.forEach(site => {
          let { properties } = site;
          if (properties) {
            let { media, Point_Name, Site_Marker_Number, index } = properties;
            if (media?.length) {
              media.forEach(image => {
                try {
                  image?.size?.mv_gallery_wide[2];
                  if (
                    image?.size?.mv_gallery_wide[1] > 1000 &&
                    image?.size?.mv_gallery_wide[2] > 700
                  ) {
                    imageFilesJoined.push({
                      ...image,
                      type: "large",
                      Point_Name,
                      Site_Marker_Number,
                      index
                    });
                  }
                } catch (error) {
                  //do nothing;
                }
              });
            }
          }
        });
      }
      imageFilesJoined = imageFilesJoined.sort(() => Math.random() - 0.5);

      //remove any double ups;
      let found = {};
      imageFilesJoined = imageFilesJoined.filter(img => {
        if (!found[img.src_gallery]) {
          found[img.src_gallery] = true;
          return true;
        } else {
          return false;
        }
      });

      if (imageFilesJoined.length > 100) {
        imageFilesJoined.length = 100;
      }
      return imageFilesJoined;
    }
  },
  methods: {
    loadMoreInfo(index) {
      //do nothing
      this.dialog = false;

      eventBus.$emit("nav_map_flyToOpenPopUp", index);

      eventBus.$emit("nav_layout_openMenu");
      eventBus.$emit("nav_sideBar_openMoreInfo");
      this.$store.dispatch("navUpdateSidebarSite", index);
    }
  },
  created() {
    eventBus.$on("loadAnimalGallery", (/*value*/) => {
      this.dialog = true;
    });
  }
};
</script>

<style scoped>
.fullHeight {
  width: 100%;
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
}

.fullHeightMax {
  max-height: calc(100vh - 65px);
}
</style>
