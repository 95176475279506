<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.mdAndDown
          ? 500
          : 800
      "
    >
      <template v-slot:activator="{ on, attrs }" v-show="showButton">
        <span
          v-bind="attrs"
          v-on="on"
          class=" "
          v-show="showButton"
          @click="scrollToSiteDelay()"
        >
          Welcome
        </span>
      </template>

      <v-card class="dialog px-3">
        <v-card-title
          v-if="true"
          :style="{
            background: 'white'
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Welcome to Melbourne Zoo
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
            lg="6"
            v-show="!$vuetify.breakpoint.mdAndDown"
            class=""
          >
            <intoVideo></intoVideo>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            id="AboutContentWrapper"
            class="contentWrapper"
            @scroll="onScroll"
          >
            <center>
              <v-card-title style="display:initial"> Wominjeka </v-card-title>

              <v-card-text>
                Zoos Victoria acknowledges and respects the Traditional Owners
                as the original custodians of the land and waters, their ability
                to care for Country and their deep spiritual connection to it.
                We honour Elders past and present whose knowledge and wisdom has
                ensured the continuation of culture and traditional practices.
              </v-card-text>

              <v-card-title style="display:initial"
                >Explore the zoo using our map
              </v-card-title>
              <v-card-text>
                Learn how to use the map here
                <v-btn x-small depressed color="primary" @click="nav_faq()">
                  FAQ</v-btn
                >

                <ToggleGeoButton
                  :isReady="isReady"
                  :dialog="dialog"
                ></ToggleGeoButton>

                <br />
                You can also use this button to change the location settings:
              </v-card-text>
              <v-img
                style="max-width: 150px;"
                src="/intro/location.png"
              ></v-img>

              <intoVideo v-show="$vuetify.breakpoint.mdAndDown"></intoVideo>
            </center>
          </v-col>
        </v-row>

        <v-card-actions class="px-0 mt-3">
          <v-btn depressed color="primary" @click="nav_faq()" v-show="false">
            FAQ
          </v-btn>
          <v-spacer v-show="false"></v-spacer>

          <transition name="fade" mode="out-in">
            <v-btn
              depressed
              color="primary"
              v-show="$vuetify.breakpoint.mdAndDown && !atTop"
              class="v-fade"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>
          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "./../../main";
import { mapState } from "vuex";
import ToggleGeoButton from "./../ui/ToggleGeoButton.vue";
import intoVideo from "./intoVideo.vue";

export default {
  name: "AboutModal",
  components: {
    ToggleGeoButton,
    intoVideo
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to Melbourne Zoo Maptivate"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },
  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `AboutContentWrapper`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("AboutContentWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  },
  mounted() {
    let bootOption = false;

    if (bootOption != true) {
      this.dialog = true;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper,
  .introVideo {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .isApple .contentWrapper,
  .isApple .introVideo {
    max-height: calc(100vh - 260px);
  }

  .isApple .imageWrapper {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
