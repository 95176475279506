<template>
  <div
    style="width:0;height:0;
    overflow: visible;"
  >
    <div class="mobileSet layerControlWrapper">
      <div class="layerControlButtonsr" v-if="!showLayer">
        <v-btn
          :title="`${!showLayer ? 'Show' : 'Close'} Layer Filters`"
          z-depth="0"
          depressed
          notsmall
          class="ml-2 pa-0"
          :color="!showLayer ? appSettings.filterLegendButtonColor : 'white'"
          @click="showLayer = !showLayer"
        >
          <v-icon v-if="showLayer">mdi-close-circle </v-icon>
          <v-icon v-if="!showLayer" color="white">mdi-layers-outline </v-icon>
        </v-btn>
      </div>

      <div v-show="showLayer" class="layerWrapper" style="">
        <v-card>
          <v-toolbar color="" dense height="35">
            <v-toolbar-title>Layer Filters</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showLayer = false" title="Close Layer Filters">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row class="px-0 pb-0 ma-0" justify="space-between" style="">
            <v-btn-toggle
              class="fakeCheckbox"
              v-model="layers"
              multiple
              style="flex-direction: column; ;"
            >
              <template v-for="item in setup_bools">
                <v-btn
                  v-show="categoryLookup[item.key].visible"
                  :key="item.key"
                  :value="item.key"
                  v-bind="iconSize"
                  :color="layers.includes(item.key) ? '#f7f7e170' : null"
                  style=""
                >
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="!layers.includes(item.key)"
                    :color="categoryLookup[item.key].color"
                    >mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="layers.includes(item.key)"
                    :color="categoryLookup[item.key].color"
                    >mdi-checkbox-intermediate
                  </v-icon>

                  <div
                    class="checkboxDiv"
                    :class="
                      layers.includes(item.key)
                        ? 'black--text text--lighten-1'
                        : 'secondary--text  text--lighten-2 '
                    "
                  >
                    {{ item.title }}
                  </div>
                </v-btn>
                <div
                  class="infoBox"
                  v-if="categoryLookup[item.key].visible"
                  :key="`info_${item.key}`"
                >
                  <div class="infoBoxAct">
                    <v-btn
                      class="v-item--active v-btn--active "
                      @click="openAbout(item.key)"
                      color="white"
                      outlined
                      x-small
                      style="       border-radius: 9px !important;margin: 2px 0px;    height: 29px!important;;"
                    >
                      <v-icon dark>
                        mdi-information-variant
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-btn-toggle>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
export default {
  name: "LayerControls",
  data: () => ({
    readOnlyButtonValues: [],
    show: false,
    showLayer: false,
    hasChangedLayer: false
  }),
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      categoryLookupRaw: state => state.markers.categoryLookup,
      setup: state => state.filters.setup,
      colors: state => state.filters.colors,
      mapStyleExtraFilters: state => state.filters.mapStyleExtraFilters
    }),
    categoryLookup() {
      return { ...this.categoryLookupRaw, ...this.mapStyleExtraFilters };
    },
    setup_bools() {
      return { ...this.setup.bools, ...this.mapStyleExtraFilters };
    },
    iconSize() {
      const size = { xs: "medium", sm: "medium", lg: "medium", xl: "medium" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    layers: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.layers;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdateLayers", value);
        eventBus.$emit("nav_filterRun");
      }
    }
  },

  methods: {
    remoteClose() {
      this.showLayer = false;
    },
    openAbout(key) {
      eventBus.$emit("loadLayerInfoDialog", key);
    }
  },
  created() {
    eventBus.$on("remote-ui-layercontrol-close", this.remoteClose);
  },
  beforeDestroy() {
    eventBus.$off("remote-ui-layercontrol-close", this.remoteClose);
  },

  watch: {
    showLayer: function(newValue) {
      if (newValue === true) {
        window.mvAnalyticEvent("ui", "layerControlsOpen", "open", false);

        eventBus.$emit("remote-ui-legned-close");
      }
    },
    layers: function(newValue, oldValue) {
      let added = newValue.filter(layer => !oldValue.includes(layer));
      let removed = oldValue.filter(layer => !newValue.includes(layer));

      if (added.length) {
        added.forEach(layer => {
          window.mvAnalyticEvent("ui", "layerShow", layer, false);
        });
      }
      if (removed.length) {
        removed.forEach(layer => {
          window.mvAnalyticEvent("ui", "layerHide", layer, false);
        });
      }
    }
  }
};
</script>

<style scoped>
div .fakeCheckbox div.infoBox {
  width: 100%;
  height: 0;
  xbackground: red;
  padding: 0;
  overflow: visible;
  z-index: 1;
}

div .fakeCheckbox div.infoBox div.infoBoxAct {
  width: 45px;
  height: 29px;
  xbackground: blue;
  padding: 0;
  float: right;
  margin-top: -33px;
  margin-right: 4px;
  overflow: visible;
}

.layerControlWrapper {
  transition: all 0.5s;
  top: 72px;
  margin-left: auto;
  position: absolute;
  /* border-radius: 2px; */
  /* width: 500px; */
  right: 6px;
}

.mobileSet {
}
.layerWrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  position: absolute;
  right: 0;
  top: 0;
}

.fakeCheckbox button {
  height: auto !important;
}
.fakeCheckbox button {
  border-radius: 0 !important;
  justify-content: left;
  font-weight: 400;
}
.fakeCheckbox div {
  width: 300px;
  white-space: none;
  white-space: normal;

  text-align: left;
  height: auto;

  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

@media only screen and (max-width: 959px) {
  .layerControlWrapper {
    top: 62px;
  }

  .fakeCheckbox button {
    border-radius: 0 !important;
    justify-content: left;
  }
  .fakeCheckbox div {
    width: 450px;
    xmax-width: calc(100vw - 62px);
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 2px;
  }

  .fakeCheckbox,
  .fakeCheckbox div,
  .layerControlWrapper {
    max-width: calc(100vw - 14px) !important;
  }
}

@media only screen and (min-width: 960px) {
  .fakeCheckbox button {
    min-height: 26px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
</style>
